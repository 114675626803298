import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#000'
  }
})

const Player = ({ video, poster, onEnded }) => {
  const classes = useStyles()

  const videoRef = React.createRef()

  // We want the video to start to play right after render.
  // autoPlay doesn't work on iOS so we need this workaround.
  useEffect(() => {
    videoRef.current.play()
    var video = document.getElementById('video');
    if (video) video.muted = false;
  }, [])

  return (
    <video
      id='video'
      muted={true}
      className={classes.root}
      src={video}
      poster={poster}
      onEnded={onEnded}
      ref={videoRef}
      preload='auto'
      controls
      playsInline
    />
  )
}

export default Player
