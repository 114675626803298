import React from 'react'
import { Box, Grid } from '@material-ui/core'
import SectionHeadline from '../../../components/SectionHeadline'
import SectionNote from '../../../components/SectionNote'
import Defects from './Defects'

const defectsProps = {
  serious: {
    title: 'Erhebliche Mängel',
    infoText: `
      Die Verkehrssicherheit ist beeinträchtigt! Nach Behebung dieser Mängel
      können Sie sicher sein, dass Ihr Fahrzeug wieder sicher & vorschriftsmäßig ist.
    `
  },
  minor: {
    title: 'Geringe Mängel',
    infoText: `
      Durch Behebung dieser Mängel erhöhen Sie die Sicherheit,
      Zuverlässigkeit und Wirtschaftlichkeit Ihres Fahrzeugs.
      Sie können zum Werterhalt Ihres Autos beitragen und den
      eigenen Geldbeutel schonen, indem Sie Verwarngelder bei
      Verkehrskontrollen vermeiden.
    `
  },
  additions: {
    title: 'Zusatzoptionen'
  }
}

const CostsBreakdown = ({ defects = [], currency }) => {
  if (!defects.length) {
    return null
  }

  const defectsSerious = defects.filter(_ => _.type === 'partset' && _.serious)
  const defectsMinor = defects.filter(_ => _.type === 'partset' && !_.serious)
  const additions = defects.filter(_ => _.type === 'additionset')

  return (
    <Box component='section' my={5}>
      <SectionHeadline>Ihre Kostenaufstellung</SectionHeadline>
      <Grid container spacing={3}>
        <Defects
          currency={currency}
          defects={defectsSerious}
          {...defectsProps.serious}
        />
        <Defects
          currency={currency}
          defects={defectsMinor}
          {...defectsProps.minor}
        />
        <Defects
          currency={currency}
          defects={additions}
          {...defectsProps.additions}
        />
      </Grid>
    </Box>
  )
}

export default CostsBreakdown
