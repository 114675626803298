import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import fetch from 'node-fetch'
import Preview from './Preview'
import Player from './Player'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%'
  }
})

const trackPlay = async orderId => {
  try {
    await fetch(`/api/orders/${orderId}/trackVideoPlay`)
  } catch (err) { }
}

const Video = ({
  orderId,
  preview,
  videoPath,
  posterPath,
  posterFallback,
  title,
  subtitle
}) => {
  const classes = useStyles()
  const [isLoaded, setIsLoaded] = useState()

  useEffect(() => {
    window.onload = async () => {
      var playButton = document.getElementById('video-play-button');
      if (playButton) {
        playButton.click();
        await delay(100);
        document.getElementById('video').muted = false;
      }
    }
  }, [])

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const loadPlayer = () => {
    setIsLoaded(true)
    if (!preview) trackPlay(orderId)
  }

  const unloadPlayer = evt => {
    setIsLoaded(false)
  }

  return (
    <div className={classes.root}>
      {isLoaded
        ? <Player
          video={videoPath}
          poster={posterPath || (posterFallback && posterFallback.data && posterFallback.data.full_url)}
          onEnded={unloadPlayer}
        />
        : <Preview
          title={title}
          subtitle={subtitle}
          video={videoPath}
          poster={posterPath || (posterFallback && posterFallback.data && posterFallback.data.full_url)}
          onPlay={loadPlayer}
        />
      }
    </div>
  )
}

export default Video
