import React, { useState } from 'react'
import fetch from 'cross-fetch'
import {
  makeStyles,
  Box,
  Typography,
  Link,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  buttons: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      whiteSpace: 'nowrap'
    }
  },
  button: {
    marginLeft: theme.spacing(2)
  }
}))

const Confirmation = ({ orderId, officeId, submitText, onConfirm, section, appointmentData }) => {
  const classes = useStyles()

  const [checked, setChecked] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false)
  const [hasFollowUpDialogOpen, setHasFollowUpDialogOpen] = useState(false)

  const handleTermsCheck = event => {
    setChecked(event.target.checked)
  }

  const handleConfirm = async () => {
    console.log("handleConfirm")
    if (!checked) {
      showDialog()
    } else if (!appointmentData['firstAppointmentWish'] && section === 'follow-up') {
      showAppointmentDialog()
    } else {
      fetch(`/api/orders/${orderId}/has-follow-up`).then(res => {
        console.log(res)
        res.json().then(body => {
          console.log(body)
          if (body.hasFollowUp) {
            showHasFollowUpDialog()
          } else {
            onConfirm()
          }
        })
      })
    }
  }

  const handleConfirmWithoutAppointment = () => {
    if (!checked) {
      showDialog()
    } else {
      onConfirm()
    }
  }

  const showDialog = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const showAppointmentDialog = () => {
    setAppointmentDialogOpen(true)
  }

  const handleAppointmentDialogClose = () => {
    setAppointmentDialogOpen(false)
  }

  const showHasFollowUpDialog = () => {
    setHasFollowUpDialogOpen(true)
  }

  const handleHasFollowUpDialogClose = () => {
    setHasFollowUpDialogOpen(false)
  }

  const checkboxLabel = (
    <Typography component='span'>
      Die <Link href={`/legal/privacy/${officeId}`}>
        Datenschutzbestimmungen
      </Link> und <Link href={`/legal/terms/${officeId}`}>
        AGB
      </Link> habe ich gelesen und akzeptiere diese.
    </Typography>
  )

  return (
    <Box p={2} className={classes.root}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={handleTermsCheck} />
          }
          label={checkboxLabel}
        />
      </FormGroup>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          className={classes.button}
          onClick={handleConfirm}
        >
          {submitText}
        </Button>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-describedby='terms-not-accepted'
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id='terms-not-accepted'>
              Bitte akzeptieren Sie unsere Nutzungsbedingungen,
              damit wir Ihren Auftrag weiter bearbeiten können.
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose} color='primary'
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={hasFollowUpDialogOpen}
        onClose={handleHasFollowUpDialogClose}
      >
        <DialogContent>
          <DialogContent>
            <DialogContentText id='hasFollowUp'>
              Es tut uns leid, aber dieser Auftrag kann nicht mehr bestätigt werden, bitte wenden Sie sich an ihren Service Berater!
            </DialogContentText>
          </DialogContent>
        </DialogContent>
      </Dialog>
      {section === 'follow-up' && (
        <Dialog
          open={appointmentDialogOpen}
          onClose={handleAppointmentDialogClose}
        >
          <DialogContent>
            <DialogContent>
              <DialogContentText id='appointment'>
                Möchten Sie uns noch Ihren Wunschtermin mitteilen?
              </DialogContentText>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleConfirmWithoutAppointment} color='primary'
              autoFocus
            >
              Ohne fohrtfaren
            </Button>
            <Button
              onClick={handleAppointmentDialogClose} color='primary'
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

export default Confirmation
