import React from 'react'
import { Typography, Container, Avatar, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Markdown from '../../../../components/Markdown'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.letter,
    color: theme.palette.text.letter
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      // To be aligned with the content below
      maxWidth: theme.breakpoints.width('lg') / 2
    }
  },
  avatar: {
    width: 42,
    height: 42
  },
  vspacing: {
    marginTop: theme.spacing(3)
  }
}))

const Letter = ({ text = '', customer, user }) => {
  const classes = useStyles()

  const username = [user.firstname, user.lastname].join(' ')

  return (
    <Paper className={classes.root} elevation={0} square>
      <Container className={classes.container}>
        <Avatar
          className={classes.avatar}
          src={`/api/users/${user.id}/photo`}
          alt={username}
        />
        <div dangerouslySetInnerHTML={{ __html: text || "" }} />
      </Container>
    </Paper>
  )
}

export default Letter
