import React from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import Tooltip from '../../../components/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderBottom: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}))

const Header = ({ infoText, children }) => {
  const classes = useStyles()

  return (
    <Box p={2} className={classes.root}>
      <Typography className={classes.title}>{children}</Typography>
      {infoText && (
        <Tooltip
          title={infoText}
          enterDelay={200}
          leaveDelay={500}
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          interactive
        >
          <Info aria-label='Info' />
        </Tooltip>
      )}
    </Box>
  )
}

export default Header
