import React from 'react'
import { Box } from '@material-ui/core'
import SectionHeadline from '../../../../components/SectionHeadline'
import ItemBox from '../ItemBox'
import Summary from './Summary'
import Appointment from './Appointment'
import Confirmation from './Confirmation'

const Assignment = ({
  orderNumber,
  section,
  customer,
  licencePlateNumber,
  costs,
  hasSelection,
  lastModified,
  orderId,
  officeId,
  blockDays,
  currency,
  appointmentData,
  onConfirm,
  onAppointmentChang,
  onFirstAppointmentChanged,
  onSecondAppointmentChanged
}) => (
  <Box component='section' my={5}>
    <SectionHeadline>Ihr Auftrag</SectionHeadline>
    {section === 'follow-up' && (
      <React.Fragment>
        <ItemBox title='Mein Wunschtermin'>
          <Appointment
            {...{
              onAppointmentChang,
              onFirstAppointmentChanged,
              onSecondAppointmentChanged,
              blockDays
            }}
          />
        </ItemBox>
        <br />
      </React.Fragment>
    )}
    <ItemBox title='Zusammenfassung' currency={currency} >
      <Summary
        {...{
          orderNumber,
          customer,
          licencePlateNumber,
          costs,
          lastModified
        }}
      />
      <Confirmation
        submitText={hasSelection ? 'Jetzt beauftragen' : 'Abwahl bestätigen'}
        {...{ orderId, officeId, onConfirm, section, appointmentData }} />
    </ItemBox>
  </Box>
)

export default Assignment
