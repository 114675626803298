import React, { useReducer, useState } from 'react'
import fetch from 'cross-fetch'
import moment from 'moment'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import CostsBreakdown from './CostsBreakdown'
import Assignment from './Assignment'

const selectedReducer = (selectedDefects, action) => {
  switch (action.type) {
    case 'add':
      if (selectedDefects.indexOf(action.defectIndex) === -1) {
        return [...selectedDefects, action.defectIndex]
      }
      return selectedDefects
    case 'remove':
      return selectedDefects.filter(_ => _ !== action.defectIndex)
  }
}

const OrderDetails = ({
  defects: rawDefects = [],
  section,
  orderId,
  orderNumber,
  customer,
  licencePlateNumber,
  lastModified,
  officeId,
  onConfirmed,
  blockDays,
  currency,
  preview
}) => {
  // For now only render for service
  if (section === 'sales' || rawDefects.length === 0) {
    return null
  }

  const [error, setError] = useState(null)

  const appointmentData = {}

  const resetError = () => setError(null)

  const defectKind = (defect) => {
    if (defect.serious) {
      return 'serious'
    } else if (defect.type === 'additionset') {
      return 'addition'
    } else {
      return 'minor'
    }
  }

  const handleConfirm = async () => {
    if (preview) {
      setError('Der Auftrag darf nur vom Kunden bestätigt werden.')
    } else {
      try {
        fetch(`/api/orders/${orderId}/has-changed`).then(res => {
          res.json().then(body => {
            if (body.hasChanged) {
              setError('Der Auftrag wurde geändert, bitte laden Sie die Seite neu, um die Änderungen sehen zu können.')
            } else {
              fetch(`/api/orders/${orderId}/confirm`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ selectedDefects, appointmentData })
              }).then(res => {
                if (res.ok) {
                  onConfirmed()
                } else if (res.status === 403) {
                  setError('Der Auftrag darf nur vom Kunden bestätigt werden.')
                } else {
                  throw new Error()
                }
              })
            }
          })
        })
      } catch (err) {
        setError('Bitte versuchen Sie es erneut, oder wenden Sie sich an Ihr Autohaus.')
      }
    }
  }

  const defects = rawDefects.map((defect, index) => ({
    ...defect,
    originalIndex: index,
    price: (defect.price.paket || 0.0) + defect.price.labor + defect.price.material,
    kind: defectKind(defect)
  }))

  const hasKind = kind => defects.some(_ => _.kind === kind)

  const initiallySelected = defects.map(_ => _.originalIndex)
  const [selectedDefects, dispatchSelection] = useReducer(selectedReducer, initiallySelected)

  const handleSelection = (defectIndex, selected) => {
    const type = selected ? 'add' : 'remove'
    dispatchSelection({ type, defectIndex })
  }

  const handleAppointmentChang = event => {
    if (event === 'clearSecondAppointment') {
      appointmentData['secondAppointmentWish'] = null
      appointmentData['secondDaytime'] = null
    } else {
      appointmentData[`${event.target.name}`] = event.target.value
    }
  }

  const handleFirstAppointmentChanged = date => {
    appointmentData['firstAppointmentWish'] = moment(date).format('YYYY-MM-DD')
  }

  const handleSecondAppointmentChanged = date => {
    appointmentData['secondAppointmentWish'] = moment(date).format('YYYY-MM-DD')
  }

  const costs = selectedDefects.reduce((sums, defectIndex) => {
    const { kind, price } = defects[defectIndex]
    sums[kind] += price
    return sums
  }, {
    // Set null to indicate there are no defects which could be (de-)selected
    serious: hasKind('serious') ? 0 : null,
    minor: hasKind('minor') ? 0 : null,
    addition: hasKind('addition') ? 0 : null
  })

  return (
    <Container>
      <CostsBreakdown
        currency={currency}
        defects={defects}
        onSelection={handleSelection}
      />
      <Assignment
        orderNumber={orderNumber}
        section={section}
        customer={customer}
        licencePlateNumber={licencePlateNumber}
        costs={costs}
        hasSelection={selectedDefects.length > 0}
        lastModified={lastModified}
        orderId={orderId}
        officeId={officeId}
        blockDays={blockDays}
        currency={currency}
        appointmentData={appointmentData}
        onConfirm={handleConfirm}
        onAppointmentChang={handleAppointmentChang}
        onFirstAppointmentChanged={handleFirstAppointmentChanged}
        onSecondAppointmentChanged={handleSecondAppointmentChanged}
      />
      <Dialog
        open={!!error}
        onClose={resetError}
        aria-labelledby='confirm-error-title'
        aria-describedby='confirm-error-description'
      >
        <DialogTitle id='confirm-error-title'>
          Es ist ein Fehler aufgetreten
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='confirm-error-description'>
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetError} color='primary' autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default OrderDetails
