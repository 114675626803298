import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  main: ({ selected }) => ({
    display: 'flex',
    alignItems: 'center',
    textDecoration: !selected && 'line-through',
    opacity: !selected && 0.5
  }),
  titleArea: {
    flex: 1
  },
  comment: ({ selected }) => ({
    color: fade(theme.palette.text.secondary, 0.7),
    fontStyle: 'italic',
    textDecoration: !selected && 'line-through'
  })
}))

const Selectable = ({
  title,
  subtitle,
  comment,
  secondary,
  selected: defaultSelected = true
}) => {
  const [selected, setSelected] = useState(defaultSelected)
  const classes = useStyles({ selected })

  const firstRun = useRef(true)
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
  })

  return (
    <Box p={2} fontSize='body1.fontSize'>
      <div className={classes.main}>
        <div className={classes.titleArea}>
          <strong>{title}</strong><br />
          <span>{subtitle}</span>
        </div>
        <strong>{secondary}</strong>
      </div>
      <div className={classes.comment}>
        {comment}
      </div>
    </Box>
  )
}

export default Selectable
