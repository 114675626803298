import React from 'react'
import { Box, makeStyles, Container, Grid } from '@material-ui/core'
import { NotificationImportant } from '@material-ui/icons'
import Markdown from '../../../components/Markdown'
import SectionHeadline from '../../../components/SectionHeadline'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    borderBottom: `2px solid ${theme.palette.divider}`
  },
  icon: {
    color: theme.palette.common.signal,
    fontSize: 35
  },
  [theme.breakpoints.down('xs')]: {
    textWrapper: {
      paddingRight: 0,
      paddingLeft: 0
    }
  }
}))

const ImportantNote = ({ text }) => {
  if (!text) {
    return null
  }

  const classes = useStyles()

  return (
    <Container component='section'>
      <Box
        mt={7}
        mb={5}
        py={3}
        className={classes.wrapper}
        fontSize='body1.fontSize'
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <SectionHeadline gutter={false}>
              Wichtige Informationen<br />
              <NotificationImportant className={classes.icon} />
            </SectionHeadline>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container className={classes.textWrapper}>
              <Markdown>{text || ''}</Markdown>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ImportantNote
