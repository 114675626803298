import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import routes from '../../lib/routes'
import DataContext from '../../lib/data-context'
import NotFound from '../../components/NotFound'
import AdBanner from '../../components/AdBanner'
import Teaser from './components/Teaser'
import ImportantNote from './components/ImportantNote'
import Documents from './components/Documents'
import OrderDetails from './components/OrderDetails'
import Contact from './components/Contact'

const Order = ({ match, loadOffice }) => {
  const { order, officeInfo, theme, officeId, preview, blockDays, currency, videoPath, posterPath } = useContext(DataContext)

  if (!order || !officeInfo) {
    return <NotFound />
  }

  const [confirmed, setConfirmed] = useState(order.confirmed)
  const handleConfirmed = () => setConfirmed(true)

  if (confirmed) {
    const redirectPath = routes.orderConfirmation.replace(':orderId', order.id)
    // On the client we need to set the location manually to achieve a server round trip.
    // On the server on the other hand we need to use the <Redirect> component.
    if (typeof window === 'undefined') {
      return <Redirect to={redirectPath} />
    } else {
      window.location.href = redirectPath
    }
  }

  return (
    order.hasFollowUp && (
      <div>
        <div style={{ padding: '8rem', fontSize: '2.5rem', textAlign: 'center', height: '82vh' }}>
          Es tut uns leid, aber dieser Auftrag ist nicht mehr erreichbar. Bitte wenden Sie sich an ihren Service Berater!
        </div>
      </div>
    ) || (
      <div>
        <Teaser
          orderId={order.id}
          images={order.images}
          videoId={order.gcpVideoId}
          vehicle={order.vehicle}
          section={order.section}
          purpose={order.purpose}
          preview={preview}
          videoPath={videoPath}
          posterPath={posterPath}
          posterFallback={theme.videoPosterFallback}
          letterText={order.letter}
          customer={order.customer}
          user={order.user}
          alignedWidth={theme.teaserAlignedWidth}
        />
        <ImportantNote text={order.message} />
        <Documents
          pdfs={order.pdfs}
          orderId={order.id}
          documents={order.documents}
        />
        <OrderDetails
          section={order.section}
          defects={order.defects}
          orderId={order.id}
          orderNumber={order.number}
          customer={order.customer}
          licencePlateNumber={order.vehicle && order.vehicle.id}
          lastModified={order.updated || order.created}
          officeId={officeId}
          onConfirmed={handleConfirmed}
          blockDays={blockDays}
          currency={currency}
          preview={preview}
        />
        <Contact user={order.user} />
        <AdBanner image={theme.adBanner} />
      </div>
    )
  )
}

export default Order
