import React from 'react'
import { Grid } from '@material-ui/core'
import ItemBox, { Selectable } from '../ItemBox'

const defectPositionWording = {
  left: 'links',
  right: 'rechts',
  front: 'vorne',
  rear: 'hinten',
  rear_left: 'hintenlinks',
  front_left: 'vornelinks',
  rear_right: 'hintenrechts',
  front_right: 'vornerechts',
}

const Defects = ({
  defects = [],
  title,
  infoText,
  currency
}) => {
  if (!defects.length) {
    return null
  }

  const getSubtitle = (defect) => {
    const { position, diagnosis, article } = defect
    if (diagnosis) {
      const translatedPositions = position ? position.map(positionItem => defectPositionWording[positionItem]) : []
      return [translatedPositions.join(", "), diagnosis].filter(_ => _).join(', ')
    } else if (article) {
      return article
    } else {
      return null
    }
  }

  const sumPrice = ({ labor, material, paket }) => labor + material + paket

  getSubtitle(defects[0])

  return (
    <Grid item xs={12}>
      <ItemBox {...{ title, infoText, currency }}>
        {defects.map(defect => (
          <Selectable
            title={defect.name}
            subtitle={getSubtitle(defect)}
            comment={defect.description}
            secondary={sumPrice(defect.price).toFixed(2)}
            inputValue={defect.originalIndex}
            key={defect.name + defect.originalIndex}
            selected={defect.ordered}
          />
        ))}
      </ItemBox>
    </Grid>
  )
}

export default Defects
