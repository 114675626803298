import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: ({ poster }) => ({
    width: '100%',
    height: '100%',
    paddingBottom: poster && '56.25%', // 16:9
    backgroundColor: '#fff',
    background: poster && `url(${poster}) center no-repeat`,
    backgroundSize: 'cover'
  }),
  layer: ({ poster, showText }) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.videoPreview,
    color: '#fff',
    textShadow: poster && '1px 1px 6px #000',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: showText && 'flex-end'
    },
  }),
  layerPosterPositioning: ({ poster }) => {
    return {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  text: {
    width: '100%',
    maxWidth: 400,
    [theme.breakpoints.up('lg')]: {
      // To be aligned with the content below
      maxWidth: (theme.breakpoints.width('lg') / 2) - theme.spacing(7)
    }
  },
  showImages: {
    position: 'absolute',
    bottom: 10,
  },
  title: {
    textTransform: 'uppercase'
  }
}))

const Preview = ({
  title,
  subtitle,
  poster,
  size,
  videoPath,
  onClick
}) => {
  const showText = !!(title || subtitle)
  const classes = useStyles({ poster, showText })

  let info
  if (showText) {
    info = (
      <div className={classes.text}>
        {title && (
          <Typography
            className={classes.title}
            variant='h4'
            component='h2'
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant='h6' component='h3'>
            {subtitle}
          </Typography>
        )}
        {videoPath && (
          <Typography variant='h6' component='h3' className={classes.showImages}>
            Klicke um {size} Bilder und ein Video anzuschauen
          </Typography>
        ) || (
            <Typography variant='h6' component='h3' className={classes.showImages}>
              Klicke um {size} Bilder anzuschauen
            </Typography>
          )}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={[classes.layer, classes.layerPosterPositioning].join(' ')} onClick={onClick}>
        {info}
      </div>
    </div>
  )
}

export default Preview
